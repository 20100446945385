<template>
  <div class="pageview">
    <div>
      <van-sticky>
        <van-field
          v-model="dataform.OrganName"
          is-link
          readonly
          placeholder="请选择所在地区"
          @click="show = true"
        />
        <!-- <van-popup v-model="show"
        position="bottom">
        <van-picker show-toolbar
          :columns="orgonList"
          value-key="organname"
          @confirm="ocodeQr"
          @cancel="show = false" />
      </van-popup> -->
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="orgonList"
            @close="show = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="onFinish"
          />
        </van-popup>
      </van-sticky>
    </div>
    <div class="bgcolor"></div>
    <!-- banner切换 v-for="banner in bannerImgs"
        :key="banner"-->
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="https://dximg.dexian.ren/bg.png" style="height: 145px" />
        <!-- <img src="https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688692580303.png"
          style="height:172px;"> -->
        <div class="title" @click="gotoUrl1('/huayang/classroom/' + InfoId)">
          邻里中心
        </div>
        <img class="shou" :src="Logo" />
        <div style="position: absolute; top: 41px; left: 45px">
          <div>
            <span class="left">姓名：</span
            ><span class="right">{{ Name }}</span>
          </div>
          <div>
            <span class="left">电话：</span
            ><span class="right">{{ Mobile }}</span>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div style="margin: 10px auto; border-radius: 5px; width: 95%">
      <div style="border-radius: 5px; background: #ffff">
        <van-grid class="wktype" :border="false" :square="true" :column-num="4">
          <template>
            <van-grid-item
              icon="photo-o"
              @click="gotoUrl1('/huayang/FnRoom/' + InfoId)"
            >
              <van-image
                class="iconimg"
                :src="require('../../assets/邻里1.png')"
              ></van-image>
              <span class="wktext">便民服务</span>
            </van-grid-item>
          </template>
          <van-grid-item
            icon="photo-o"
            @click="gotoUrl1('/huayang/firmlogin/' + InfoId)"
          >
            <van-image
              class="iconimg"
              :src="require('../../assets/邻里2.png')"
            ></van-image>
            <span class="wktext">便民食堂</span>
          </van-grid-item>
          <van-grid-item
            icon="photo-o"
            @click="gotoUrl1('/guanghe/evaluating/' + cascaderValue)"
          >
            <van-image
              class="iconimg"
              :src="require('../../assets/邻里3.png')"
            ></van-image>
            <span class="wktext">房屋租赁</span>
          </van-grid-item>
          <template>
            <van-grid-item
              icon="photo-o"
              @click="gotoUrl1('/huayang/comment/' + cascaderValue)"
            >
              <van-image
                class="iconimg"
                :src="require('../../assets/邻里4.png')"
              ></van-image>
              <span class="wktext">积分</span>
            </van-grid-item>
          </template>
        </van-grid>
      </div>
    </div>

    <div>
      <van-swipe
        class="homebanner"
        v-if="Neighbor.Imgs && Neighbor.Imgs != ''"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item v-for="banner in Neighbor.Imgs.split(',')" :key="banner"
          ><img :src="banner" style="height: 150px" />
        </van-swipe-item>
      </van-swipe>
      <van-swipe
        class="homebanner"
        v-else
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item v-for="banner in Arr" :key="banner"
          ><img :src="banner" style="height: 150px" />
        </van-swipe-item>
      </van-swipe>
      <!-- <router-link to="/register"> -->
      <!-- <div>
        <img
          style="width: 88%; margin: 0px 5%; border-radius: 8px"
          src="https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691428215.png"
          alt=""
        />
      </div>
      <div>
        <img
          style="width: 88%; margin: 0px 5%; border-radius: 8px"
          src="https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691448840.png"
          alt=""
        />
      </div> -->
      <!-- </router-link> -->
    </div>
    <!-- <br class="brakline" /> -->
    <!-- <div style="width: 95%;margin: 15px auto">
      <div class="essay"
        style="margin:0;width:100%;padding:0;">
        <div class="essayContent"
          style="padding:0;width:100%;box-shadow: 0px 0px 4px #e7e1e1;border-radius: 11px;"
          v-html="appInfo.WorkTime"></div>
      </div>
    </div> -->
    <div
      style="
        background: #fff;
        margin: 0 auto 10px;
        width: 95%;
        border-radius: 5px;
      "
    >
      <div
        style="
          padding-top: 15px;
          display: flex;
          width: 100%;
          justify-content: space-between;
        "
      >
        <!-- <img src="../../assets/五星.png"
          alt=""
          width="16px"> -->
        <div class="grouptitle">活动信息</div>
        <span
          class="moreCount"
          @click="gotoUrl('/partymember/consult/' + cascaderValue)"
          >更多
          <img
            style="width: 5.5px; vertical-align: middle"
            src="https://dximg.dexian.ren/more.png"
            alt=""
        /></span>
      </div>
      <div class="information">
        <div class="center" v-for="item in commNoticeList" :key="item.index">
          <div @click="gotoUrl('/neighbor/detail/' + item.AId)">
            <div
              class="Content"
              style="
                border-bottom: 1px solid rgb(245 243 243);
                position: relative;
              "
            >
              <div
                style="display: inline-block; width: 32%"
                class="ContentA"
                ref="getHeight"
              >
                <span v-if="item.AImg">
                  <img
                    :src="item.AImg"
                    alt=""
                    style="min-height: 80px; height: 100px"
                  />
                </span>
                <span v-else>
                  <img
                    src="http://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
                    alt=""
                    style="min-height: 80px; height: 100px"
                  />
                </span>
              </div>
              <div class="ContentB">
                <div class="centerTitle van-ellipsis--l2">
                  {{ item.Title }}
                </div>
                <div
                  class="centertime van-multi-ellipsis--l2"
                  style="margin: 28px 0px; font-size: 13px; color: #666666"
                >
                  {{ item.Summary }}
                </div>
                <div class="centertime">
                  <span>{{ item.IssTime | timeFilter }}</span>
                  <!-- <span style="padding-left:10px;"> 阅读 {{item.ReadNum}}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无信息" v-if="!commNoticeList.length" />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index";
import {
  WeMyMemberInfo,
  WxGetNeInfoOfOrgan,
  WeGetRealInfoPage,
  WeGetMyActivityPage,
  WxGetRecruitActivityPage,
  WxGetNeInfo,
} from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
import { GetChildOrgansNullToken, WeGetOrganTree } from "@/api/Organ";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      Arr: [
        "https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691428215.png",
        "https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691448840.png",
      ],
      Name: "",
      Mobile: "",
      Code: "",
      appInfo: {},
      bannerImgs: [{ url: "../../assets/dengji.png" }],
      active: 0,
      partyNoticeList: [],
      commNoticeList: [
        {
          Title: "满满烟火气！社区邻里中心首创 本土邻里日品牌...",
          IssTime: "2023-07-05 08:00:12",
          Thematic:
            "https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691737011.png",
        },
        {
          Title: "高标准打造邻里中心，是高质量 创建全国文明城市...",
          IssTime: "2023-07-04 08:00:35",
          Thematic:
            "https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1688691752403.png",
        },
      ],
      show: false,
      area: "",
      orgonList: [],
      cascaderValue: "",
      dataform: {
        OrganName: "吉利街道",
        OrgCode: "",
      },
      InfoId: "",
      Logo: "",
      Neighbor: {},
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        let gstime = value.replace("T", " ");
        // let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  created() {
    // setOpenId("oas2d4qNkpHqYBS0NlA4glPsVR1k"); //测试openid
    // this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    // 活动五星党建亮点
    // this.getNoticeList('书记茶话会');
    // this.getNoticeList('多元化社区');
    // this.getNoticeList('社群e家联防巡逻');
    // this.getNoticeList('爱心大妈');
    // this.getNoticeList('餐饮专题培训');
    // 获取活动通知
    // this.getCommNoticeList();
    this.getOrgonTree();
    this.getMyMemberInfo();
  },
  methods: {
    // 获取活动 信息

    // 获取邻里中心 详情
    async getNeInfo1() {
      console.log(11111111111);
      // this.Neighbor = {};
      const res = await WxGetNeInfo({ infoId: this.InfoId });
      console.log(res, "邻里中心 详情");
      if (res.data.data) {
        this.Neighbor = res.data.data;
      } else {
        this.Neighbor = {};
      }
    },
    // 组织机构
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
        this.dataform.OrganName = res.data.data[0].OName;
        this.oCode = res.data.data[0].OName;
        this.OrgCode = res.data.data[0].Children[0].OCode;
        let Code = this.getAllIds(this.orgonList);
        Code.forEach((i) => {
          if (this.cascaderValue == i.OCode) {
            this.dataform.OrganName = i.OName;
          }
        });
        this.getCommNoticeList();
      });
    },
    // 循环遍历树形结果
    getAllIds(tree) {
      let result = [];
      if (!Array.isArray(tree)) {
        return result;
      }
      tree.forEach((node) => {
        result.push({ OCode: node.OCode, OName: node.OName });
        if (Array.isArray(node.Children)) {
          // result.push(...getAllIds(node.children));
          result = result.concat(this.getAllIds(node.Children));
        }
      });
      return result;
    },

    // 获取会员详情
    getMyMemberInfo() {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        console.log(res, "会员详情");
        this.Name = res.data.data.Name;
        this.Mobile = res.data.data.Mobile;
        this.cascaderValue = res.data.data.OrgCode;
        this.getNeInfo(this.cascaderValue);
      });
    },

    // 获取邻里中心 详情
    async getNeInfo(Code) {
      const res = await WxGetNeInfoOfOrgan({ orgCode: Code });
      console.log(res, "邻里中心");
      if (res.data.data) {
        this.InfoId = res.data.data.InfoId;
        this.Logo = res.data.data.Logo;
        this.getNeInfo1();
      } else {
        this.InfoId = 0;
        this.Logo = "https://dximg.dexian.ren/shou.png";
      }
    },
    gitocodelist() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
      });
    },
    // 社区搜索
    onFinish: function (val) {
      this.show = false;
      console.log(val, "val");
      this.fieldValue = val.selectedOptions
        .map((option) => option.OName)
        .join("/");
      this.dataform.OrganName = this.fieldValue;
      this.OCode = val.value;
      this.cascaderValue = val.value;

      this.getNeInfo(this.cascaderValue);
      this.getNeInfo1();
      this.getCommNoticeList();
      // this.SerOrganList = []
      // this.getSerOrganList()
      // this.getSerOrganCountSearch(this.OCode)
    },
    //跳转到公共页面
    gotoUrl1(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取活动通知
    getCommNoticeList() {
      WxGetRecruitActivityPage({
        page: 1,
        limit: 10,
        orgCode: this.cascaderValue,
        akCate: 4,
      }).then((res) => {
        if (res.data.code == 0) {
          this.commNoticeList = res.data.data;
          console.log(this.commNoticeList, " this.commNoticeList");
        }
      });
    },
    // 通知消息 五星支部创建
    getNoticeList(row) {
      WeGetMyActivityPage({
        taskClassification: row,
        page: 1,
        limit: 10,
        openid: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          this.partyNoticeList.push(res.data.data[0]);
          for (var i = 0; i < this.NoticeList.length; i++) {
            var item = this.NoticeList[i].IssTime;
            item = this.dataFormat(item);
            this.partyNoticeList[i].IssTime = item;
          }
          console.log(this.partyNoticeList, " this.partyNoticeList");
        }
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1;
          imgs[1] = list.data.SysPic2;
          imgs[2] = list.data.SysPic3;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.ContentB {
  display: inline-block;
  width: 62% !important;
  margin-left: 3% !important;
}

.bgcolor {
  position: absolute;
  /* background: linear-gradient(#f63e3b, #fff); */
  /* background: #f63e3b; */
  height: 150px;
  width: 100%;
}

.pageview {
  min-height: 100%;
  background: #fff;
}

.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 15px;
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}

.wktime {
  padding: 0 20px;
}

.wktype {
  padding: 0 5px 5px;
}

.orginview .iconimg {
  width: 62%;
}

.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}

.wktype .iconimg {
  width: 60%;
}

.wktype .wktext {
  margin-top: 8px;
  font-size: 0.75rem;
}

.grouptitle {
  /* text-indent: 5px;
  font-size: 18px;
  color: #231815;
  font-weight: 700; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}

.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fuwenben p {
  margin: 0 !important;
}

.moreCount {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-right: 4px;
}
.title {
  position: absolute;
  bottom: 25px;
  left: 20%;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
}
.homebanner .shou {
  width: 17.8%;
  position: absolute;
  top: 52%;
  right: 10.8%;
  transform: translateY(-50%);
}

.left,
.right {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
}

.left {
  font-weight: 100;
}

.ContentB .centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231815;
}
.ContentB .centertime {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
